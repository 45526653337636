@import "variables";

:root {
    font-family: Arial, Helvetica, sans-serif;
    background-color: $colorBase1;
}

h1, h2, h3, h4 {
    margin: 0;
    color: $colorBase1;
}

h1 {
    font-size: 8ch;
    color: $colorPop1;
    margin-bottom: 5px;

    @media (max-width: $breakingPointPhone) {
        font-size: 12vw;
    }
}

h2 {
    @media (max-width: $breakingPointPhone) {
        font-size: 5vw;
    }
}

input[type=text], input[type=password] {
    padding: 3px;
    font-size: 0.9rem;
    
    border: {
        width: 2px;
        radius: 10px;
        style: solid;
        color: $colorPop3;
    }
}

button {
    padding: 7px;
    background-color: $colorPop1;
    color: $colorBase1;
    font-weight: 700;
    display: flex;

    cursor: pointer;
    
    border: {
        radius: 10px;
        width: 0;
    }

    &:active {
        background-color: $colorPop6;
    }

    // styles for icons
    $iconSize: 1.7em;
    img {
        height: 100%;
        max-width: $iconSize;
        filter: $colorFilterBase1;
        -webkit-user-drag: none;
    }
}

a, div[class=logOutButton] {
    color: $colorPop3;
    text-decoration: none;
}