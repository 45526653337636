@import "variables";
@import "mixins";

.main {
    @include page;

    min-height: calc(100vh - 2 * $headerHeight);
    margin-top: 2 * $headerHeight;
}

.paddedElement {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;

    div[class=buttons] {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        
        button {
            margin-right: 5px;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 2 * $headerHeight;
    z-index: 10;


    div[class=headerElement] {
        background-color: $colorPop3;
        height: $headerHeight;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: {
            left: 10px;
            right: 10px;
        }

        input {
            width: 100%;
            margin-right: 10px;
        }
    }
}

.floating {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $colorBase1;
    border: 0;
}

.participants {
    color: $colorPop5;
    display: flex;
    flex-direction: row;
    & > div {
        margin-left: 4px;
    }
}

.spacedRow {
    display: flex;
    flex-direction: row;
    & > div, button {
        margin-left: 4px;
    }
}

.headerButton {
    font-size: large;
    border-radius: 100%;
    width: 3ch;
    height: 3ch;
    align-items: center;
}

.mainElement {
    user-select: none;
    -webkit-user-select:none;
    -webkit-touch-callout: none;

    &:last-child {
        margin-bottom: 4em;
    }
}

.refreshAnimation {
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    animation: {
        name: spin;
        duration: 500ms;
        timing-function: ease-in-out;
    };
}

.orderByContainer {
    $backgroundColor: $colorPop4;
    position: absolute;
    top: 3em;
    cursor: default;

    div[class=triangle] {
        position: relative;
        left: 3px;
        width: 0;
        height: 0;
        border: {
            width: 0.5rem;
            style: solid;
            left-color: transparent;
            right-color: transparent;
            top-color: transparent;
            bottom-color: $backgroundColor;
        }
    }

    div[class=options] {
        z-index: 11;
        position: fixed;
        right: 1rem;

        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.7rem;
        
        border-radius: 20px;
        width: calc(100% - 2rem - (2 * 0.7rem));
        max-width: 25rem;

        background-color: $backgroundColor;
        color: $colorText;

        div {
            font-size: 0.9rem;
            text-align: left;
            font-weight: normal;
            padding: 1rem;
            background-color: $colorBase1;
            border-radius: 10px;
            cursor: pointer;

            &:first-child {
                padding: 0;
                font-size: 1rem;
                background-color: $backgroundColor;
                color: $colorBase1;
                cursor: default;
            }

            &[class=selected] {
                background-color: $colorPop2;
            }
        }
    }
}

.helpMessage {
    $helpMessageColor: $colorPop1;
    color: $helpMessageColor;
    font-weight: bold;
    margin-top: 2rem;

    h3 {
        text-align: center;
        color: $helpMessageColor;
    }

    ul {
        width: calc(100% - 50px);
        max-width: 400px;
        margin: auto;
        padding: {
            left: 10px;
            right: 10px;
        }
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-top: 1em;
    }

    img {
        max-height: 1rem;
        max-width: 1rem;
        filter: $colorFilterPop1;
        -webkit-user-drag: none;
    }
}
