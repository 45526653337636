//* document with global variables
$headerHeight: 50px;

// breaking points
$breakingPointPhone: 600px;
$breakingPointSmallScreen: 1024px;

// colors
$colorPop1: #6E8898;
$colorPop2: #5BC3EB;
$colorPop3: #467599;
$colorPop4: #6ba1ca;
$colorPop5: #C78283;
$colorPop6: #19647E;
$colorBase1: #FFF8F0;
$colorText: #042A2B;
$colorFilterPop1: invert(54%) sepia(18%) saturate(451%) hue-rotate(159deg) brightness(93%) contrast(93%);
$colorFilterBase1: invert(95%) sepia(6%) saturate(1723%) hue-rotate(305deg) brightness(110%) contrast(102%);