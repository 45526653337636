@import "variables";

@mixin page {
    min-height: 100vh;

    background-color: $colorBase1;
    color: $colorText;
}

@mixin centeredPage {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div[class=content] {
        margin: 10px;
        max-width: 1000px;
    }
}

@mixin form {
    h1[class=centered] {
        text-align: center;
    }

    div[class=formElement] {
        display: flex;
        flex-direction: column;

        margin: {
            top: 10px;
            bottom: 10px;
        }

        label {
            font-size: smaller;
        }
    }

    div[class=errorMessage] {
        color: red;
        font-weight: bold;
        text-align: center;

        min-height: 2ch;
    }

    div[id=buttonContainer] {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        margin-bottom: 10px;
        
        button {
            flex: 1;
            justify-content: center;
        }
    }
}