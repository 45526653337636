@import "variables";
@import "mixins";

.checkedElement {
    background-color: $colorPop5;
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 0;

    animation: {
        name: appear;
        duration: 200ms;
        timing-function: ease-in;
    };

    @keyframes appear {
        from {
            transform: scaleX(0);
        }
        to {
            transform: scaleX(1);
        }
    }
}

.styleSelection {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    
    & > div {
        $gap: 0.7em;
        min-width: 2em;
        margin-right: 5px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap;
        padding: $gap / 1.7;
        border-radius: 10px;

        background-color: $colorPop1;
        color: $colorBase1;
        
        & > div {
            cursor: pointer;

            text-align: center;
            width: 1.5em;
            height: 1.5em;
            border-radius: 100%;

            border: {
                width: 2px;
                style: solid;
                color: $colorBase1;
            }

            &:nth-child(1) {
                font-weight: normal;
                font-style: normal;
                border: unset;
            }

            &:nth-child(2) {
                font-weight: bold;
                font-style: normal;
                border: unset;
            }

            &:nth-child(3) {
                font-weight: normal;
                font-style: italic;
                border: unset;
            }
        }
    }
}

.editingStyle {
    $size: 3px;
    box-shadow: 0px $size 0px $colorPop3, 0px (-$size) 0px $colorPop3;
}